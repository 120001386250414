<template>
  <layout>
      <!--注册-->
    <div >
      <div class="title">
        神笔电商服务平台
      </div>
      <el-form ref="registerForm" :model="registerForm" :rules="registerRules" >
        <el-form-item prop="name">
          <div class="inputBox">
            <el-input v-model="registerForm.name" placeholder="请输入您的名字" class="input" >
              <img src="../../assets/userIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="account">
          <div class="inputBox">
            <el-input v-model="registerForm.account" placeholder="请输入您的手机号码" class="input" >
              <img src="../../assets/userIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="password" :rules="[{required:true,message:'请输入您的登录密码',trigger:['change','blur']}]">
          <div class="inputBox">
            <el-input type="password" v-model="registerForm.password" placeholder="请输入密码" class="input">
              <img src="../../assets/passwordIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="password1" :rules="[{required:true,validator:validator,trigger:['blur']}]">
          <div class="inputBox">
            <el-input type="password" v-model="registerForm.password1" placeholder="请再次输入密码" class="input">
              <img src="../../assets/passwordIcon.png" class="icon" slot="prepend" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div style="display: flex;align-items: center">
            <div class="inputBox">
              <el-input v-model="registerForm.verifyCode" class="input" style="margin-right: 4px" placeholder="请输入手机验证码">
                <img src="../../assets/codeIcon.png" class="icon" slot="prepend" >
              </el-input>
            </div>
            <el-button @click="sendMsg" type="primary" style="height: 48px;width:160px;border-radius: 8px;text-align: center;" :disabled="disabled">
              {{ time?time+'S':'发送验证码'}}
            </el-button>
          </div>
        </el-form-item>
        <el-form  class="login">
          <el-button @click="register" style="width: 100%" :loading="loading">注册</el-button>
        </el-form>
      </el-form>
      <div class="more">
        <span class="textBut" style="margin-right: 20px" @click="goLogin">登录账号</span>
        <!--                                <span class="textBut" @click="status =3">忘记密码</span>-->
      </div>


    </div>

  </layout>
</template>

<script>
    import {REGISTER,SMS} from '../../api/base'
    import Layout from '../login/layout'
    import {validatePhone} from  '../../tools/validate'
    export default {
        name: "register",
        data() {
            const validator =  (rule, value, callback)=>{
              if(value==''){
                return  callback(new Error('请再次输入登录密码'))
              }
              console.log(this.registerForm.password)
              if(value !== this.registerForm.password){
                return callback(new Error('两次输入的登录密码不一样'))
              }else{
                return callback()
              }
            }
            return {
              validator,
                formData: {
                    account: '',
                    password: '',
                    captcha: '',
                },
                registerRules:{
                  name:[{required:true,message:'请输入您的名称',trigger:['change','blur']}],
                  account:[{required:true,validator:validatePhone,trigger:['change','blur']}],
                  verifyCode:[{required:true,message:'请输入验证码',trigger:['change','blur']}],
                },
                registerForm:{
                    name:'',
                    account: '',
                    password: '',
                    password1: '',
                    verifyCode: '',
                },
                time:0,
                disabled:false,
                status:1,
                img: '',
                show:false,
                loading:false,

            }
        },
        methods: {
          goLogin(){
            this.$router.push({path:'/login'})
          },
           sendMsg(){
             this.$refs.registerForm.validateField('account',(flag)=>{
               if(!flag){
                 const obj = {
                   mobile:this.registerForm.account,
                   tenantId:this.registerForm.tenantId,
                 }
                 this.$axios.post(SMS,obj).then(res => {
                   console.log(res)
                   if(res.code ==200){
                     this.$message({message:res.msg})
                     this.disabled = true;
                      this.time = 60
                     let $times = setInterval(()=>{
                       this.time--;
                       if(this.time ==0){
                         this.disabled = false;
                         clearInterval($times)
                         $times = null
                       }
                     },1000)
                   }

                 }).catch(err => {
                   this.$mes({message: err.msg, type: 'error'})

                 })
               }
             })

           },
           register(){
            console.log(this.$refs.registerForm)
            this.$refs.registerForm.validate((validate)=>{
              if(this.registerForm.verifyCode.length != 6){
                return false;
              }
              if(validate){
                this.loading = true;
                this.$axios.post(REGISTER, this.registerForm).then(res => {
                  this.loading = false;
                  console.log(res)
                  if (res.code == 200) {
                    this.$mes({message: res.msg});
                    const $time = setTimeout(()=>{
                      clearTimeout($time)
                      this.$router.push({path:'/login'})
                    },1000)
                  }
                }).catch((err) => {
                  this.$mes({message: err.msg, type: 'error'})
                  this.loading = false;

                })
              }
            })
          },
      },
      mounted() {
        this.registerForm.tenantId = this.$route.query.tenantId;
        this.registerForm.secretKey = this.$route.query.secretKey;
        console.log(this.registerForm)
      },
      components: {
        Layout,
      }
    }
</script>

<style scoped lang="less">
    @inputColor: #F9DFCD;
    .login{
        /deep/ .el-button--default{
            border-radius: 8px;
            background: linear-gradient(134.99deg, #FCB358 0%, #F9804D 100%);
            color: white;
        }
    }
    /deep/ input::-webkit-input-placeholder {
        color: #999;
    }
    /deep/ input:-moz-placeholder {
        color: #999;
    }
    /deep/ input:-ms-input-placeholder {
        color: #999;
    }
    .inputBox{
        display: flex;
        /deep/ .el-input-group__prepend{

            background-color: @inputColor;
        }
        .icon{
            width: 20px;
            height: 20px;
        }
    }
    .my-enter,.my-leave-to{
        opacity: 0;
        transform: translateX(80px);
    }
    /*进入和离开的动画时间段*/
    .my-enter-active,.my-leave-active{
        transition: all 0.5S ease
    }
    .more{
        padding-top: 20px;
        text-align: right;
        color: #999999;
        cursor: pointer;
        .textBut{

        }

    }
    .input{
        border: 2px solid #F9834E;
        border-radius: 8px;
        /deep/ .el-input__inner{
            background-color: @inputColor !important;
            border: 0px !important;

        }
        /deep/ .el-input-group__prepend{
            border: 0px !important;
        }


    }
    .title{
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      margin-bottom: 24px;
    }
</style>